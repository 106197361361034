import { classnames } from '@tailwindcss-classnames';

// eslint-disable-next-line import/prefer-default-export

export const base = classnames(
  'font-bold',
  'text-xs',
  'py-1',
  'px-2',
  'rounded',
  'inline-block'
);

export const outOfStock = classnames(
  'text-nzxt-light-grey-800',
  'border',
  'border-nzxt-light-grey-800'
);
export const lowStock = classnames('bg-nzxt-yellow-200', 'text-nzxt-brown-500');
export const onSale = classnames('bg-nzxt-green-400', 'text-white');
export const isPromo = classnames('bg-nzxt-green-200', 'text-nzxt-green-500');
export const invalid = classnames(
  'text-nzxt-red-400',
  'border',
  'border-nzxt-red-400'
);
export const newItem = classnames('bg-nzxt-blue-400', 'text-white');
export const comingSoon = classnames(
  'text-nzxt-blue-400',
  'border',
  'border-nzxt-blue-400'
);
