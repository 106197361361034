import { memo, FC } from 'react';
import usePrice from '@framework/use-price';
import type { DatoProduct } from '@framework/api/types';
import * as styles from './styles';

type Props = {
  currencyCode: string;
  product: DatoProduct;
  optionalClass?: string;
};

// TODO: Remove 'suppressHydrationWarning' from price items
// suppressHydrationWarning is a temporary solution for the server/client mismatch

const ProductSectionPrice: FC<Props> = ({
  currencyCode,
  product,
  optionalClass,
}) => {
  const { collectionPagePrice } = product;

  const { price } = usePrice({
    amount: collectionPagePrice,
    currencyCode,
  });

  return (
    <p
      suppressHydrationWarning
      className={optionalClass || styles.productPrice}
    >
      {price}
    </p>
  );
};

export default memo(ProductSectionPrice);
