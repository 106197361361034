import { classnames } from '@tailwindcss-classnames';

const defaultHeading = classnames('text-xs', 'font-bold');
const hasNoDetails = classnames('text-base');
const smallText = classnames('text-xs', 'md:text-sm');

export const heading = (hasDetail: boolean, isSmall: boolean): string =>
  classnames({
    [defaultHeading]: hasDetail,
    [hasNoDetails]: !hasDetail && !isSmall,
    [smallText]: !hasDetail && isSmall,
  });

const fake = classnames('flex', 'space-x-2');
export const subheading = (isFauxPrice: boolean): string =>
  classnames('text-xs', 'text-nzxt-light-grey-700', {
    [fake]: isFauxPrice,
  });

const defaultBorder = classnames('border-t', 'pt-4', 'mb-4', 'flex-1');

export const container = (isFauxPrice: boolean): string =>
  classnames({ [defaultBorder]: !isFauxPrice }, 'flex-grow');
