import { classnames } from '@tailwindcss-classnames';

const smallHeading = classnames('text-sm', 'md:text-lg');
const defaultHeading = classnames('text-lg');

export const heading = (isSmall: boolean): string =>
  classnames('font-bold', 'text-nzxt-light-grey-800', {
    [smallHeading]: isSmall,
    [defaultHeading]: !isSmall,
  });
export const shortDescription = classnames('flex', 'col-span-2', 'w-full');
export const descriptionColor = classnames(
  'text-nzxt-light-grey-600',
  'text-xs'
);

export const container = classnames('mb-4');
