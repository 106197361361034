/* eslint-disable react/jsx-no-useless-fragment */
import React, { FC, memo, ReactNode, useMemo, HTMLProps } from 'react';
import cx from 'clsx';
import Link from 'next/link';
import * as styles from './styles';
import buttonStyles from './buttonStyles.module.css';

type ButtonLinkProps = {
  children: ReactNode;
  buttonStyle?: string;
  className?: string;
  dark?: boolean;
  internalLink?: boolean;
  href?: string;
  customMargin?: boolean;
  disabled?: boolean;
  asButton?: boolean;
  noChevron?: boolean;
  id?: string;
  dataTestId?: string;
};

type ButtonProps = ButtonLinkProps & HTMLProps<HTMLAnchorElement>;

const ButtonLink: FC<ButtonProps> = ({
  children,
  buttonStyle,
  className = '',
  dark = false,
  internalLink = false,
  href,
  customMargin,
  disabled,
  asButton,
  noChevron,
  id,
  dataTestId,
  ...rest
}) => {
  const buttonType =
    buttonStyle === 'primary' || buttonStyle === 'secondary'
      ? buttonStyle
      : 'primary';

  const linkType =
    buttonStyle === 'primary' || buttonStyle === 'secondary'
      ? buttonStyle
      : 'tertiary';

  let TestId;
  if (!dataTestId) {
    TestId = internalLink ? 'internal-link-block' : 'external-link-block';
  } else {
    TestId = dataTestId;
  }

  const composedClassNameButton = useMemo(
    () =>
      cx(
        styles.button(customMargin, disabled),
        dark
          ? styles.buttonModeDark[buttonType]
          : styles.buttonModeLight[buttonType],
        className,
        buttonStyles.btn
      ),
    [className, dark, disabled, customMargin, buttonType]
  );

  const composedClassNameLink = useMemo(
    () =>
      cx(
        styles.linkBase(disabled),
        dark ? styles.baseLinkDark[linkType] : styles.baseLinkLight[linkType],
        className,
        { [buttonStyles.link]: !noChevron }
      ),
    [linkType, className, dark, disabled, noChevron]
  );

  const buttonClassName = asButton
    ? composedClassNameButton
    : composedClassNameLink;

  return (
    <>
      {internalLink ? (
        <Link href={href} passHref>
          <a
            className={buttonClassName}
            {...rest}
            id={id}
            data-test-id={TestId}
          >
            {children}
          </a>
        </Link>
      ) : (
        <a
          href={href}
          className={buttonClassName}
          {...rest}
          data-test-id={TestId}
        >
          {children}
        </a>
      )}
    </>
  );
};

export default memo(ButtonLink);
