/* eslint-disable no-nested-ternary */
import { CSSProperties, memo, useMemo, FC, ReactNode } from 'react';
import cx from 'clsx';
import * as styles from './styles';

// TODO - handle this any
type Props = {
  children?: ReactNode;
  className?: string;
  id?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref?: any;
  style?: CSSProperties;
  noSpacing?: boolean;
};

const SectionWrapper: FC<Props> = ({
  children,
  className,
  id,
  ref,
  style,
  noSpacing = false,
  ...rest
}) => {
  const composedClassName = useMemo(
    () =>
      !noSpacing
        ? cx(styles.sectionWrapper, className)
        : className
        ? cx(className)
        : null,
    [className, noSpacing]
  );

  return (
    <section
      ref={ref}
      className={composedClassName}
      id={id}
      {...rest}
      style={style}
    >
      {children}
    </section>
  );
};

export default memo(SectionWrapper);
