/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, FC } from 'react';
import ContentWrapper from '@components/ContentWrapper';
import type { DatoProduct } from '@framework/api/types';

import SingleItemTableSpecs from './SingleItemTableSpecs';
import * as styles from './styles';

type Props = {
  products: DatoProduct[];
};

const SingleItemTable: FC<Props> = ({ products }) => (
  <ContentWrapper>
    {products.map(product => (
      <div key={product.name} className={styles.specsSection}>
        <SingleItemTableSpecs decoratedSpecs={product.decoratedSpecs} />
      </div>
    ))}
  </ContentWrapper>
);

export default memo(SingleItemTable);
