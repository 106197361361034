/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useMemo } from 'react';
import { useCommerce } from '.';

type Opts = {
  style: string;
  currency: string;
  maximumSignificantDigits?: number;
};

export function formatPrice({
  amount,
  currencyCode,
  locale,
  maximumSignificantDigits = null,
}: {
  amount: number;
  currencyCode: string;
  locale: string;
  maximumSignificantDigits?: number;
}) {
  const opts: Opts = {
    style: 'currency',
    currency: currencyCode,
  };

  if (maximumSignificantDigits) {
    opts.maximumSignificantDigits = maximumSignificantDigits;
  }

  const formatCurrency = new Intl.NumberFormat(locale, opts);

  return formatCurrency.format(amount);
}

export function formatVariantPrice({
  amount,
  baseAmount,
  currencyCode,
  locale,
  maximumSignificantDigits,
}: {
  baseAmount: number;
  amount: number;
  currencyCode: string;
  locale: string;
  maximumSignificantDigits?: number;
}) {
  const hasDiscount = baseAmount > amount;
  const formatDiscount = new Intl.NumberFormat(locale, { style: 'percent' });
  const discount = hasDiscount
    ? formatDiscount.format((baseAmount - amount) / baseAmount)
    : null;

  const price = formatPrice({
    amount,
    currencyCode,
    locale,
    maximumSignificantDigits,
  });
  const basePrice = hasDiscount
    ? formatPrice({
        amount: baseAmount,
        currencyCode,
        locale,
        maximumSignificantDigits,
      })
    : null;

  return { price, basePrice, discount };
}

export default function usePrice(
  data?: {
    amount: number;
    baseAmount?: number;
    currencyCode: string;
    maximumSignificantDigits?: number;
  } | null
) {
  const { amount, baseAmount, currencyCode, maximumSignificantDigits } =
    data ?? {};
  const { locale } = useCommerce();
  const value = useMemo(() => {
    if (typeof amount !== 'number' || !currencyCode) return '';

    return baseAmount
      ? formatVariantPrice({
          amount,
          baseAmount,
          currencyCode,
          locale,
          maximumSignificantDigits,
        })
      : formatPrice({ amount, currencyCode, locale, maximumSignificantDigits });
  }, [amount, baseAmount, currencyCode, locale, maximumSignificantDigits]);

  return typeof value === 'string' ? { price: value } : value;
}
