/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, FC } from 'react';
import { useRouter } from 'next/router';
import { getRegion } from '@framework/api/utils/maxify';
import Image from '@components/Image';
import { ROUTE_PRODUCT } from '@constants';
import type { DatoProduct } from '@framework/api/types';
import { useTranslation as t } from '@utils/hooks';
import ButtonLink from '@components/ButtonLink';
import ProductSectionPrice from './ProductSectionPrice';
import * as styles from './styles';

type Props = {
  products: DatoProduct[];
  currencyCode: string;
  isScroll?: boolean;
};

const ProductSection: FC<Props> = ({ products, currencyCode, isScroll }) => {
  const { locale } = useRouter();
  const region = getRegion(locale);
  const PRODUCTS_LABEL = t('products_label');
  const DETAILS_LABEL = t('details');

  return (
    <tr>
      <th className={styles.dataTableHeadingsFaux(isScroll)} scope="row">
        {PRODUCTS_LABEL}
      </th>

      {products.map(product => {
        const isRegionallyAvailable = Array.isArray(product?.availableRegions)
          ? product?.availableRegions.some(r => r.region === region)
          : false;

        return (
          <td key={product.id} className={styles.productTableData}>
            <div className={styles.productWrapper}>
              {product.collectionPageMainImage && (
                <Image
                  className={isScroll && styles.scrollProductSectionImage}
                  data={product.collectionPageMainImage.responsiveImage}
                  width={product.collectionPageMainImage.responsiveImage.width}
                  height={
                    product.collectionPageMainImage.responsiveImage.height
                  }
                />
              )}
              <p key={product.id} className={styles.headingHeader}>
                {product.name}
              </p>
              {isRegionallyAvailable &&
                product.variants &&
                product.variants.length > 0 && (
                  <ProductSectionPrice
                    currencyCode={currencyCode}
                    product={product}
                  />
                )}
              <ButtonLink
                asButton
                buttonStyle="secondary"
                href={`/${ROUTE_PRODUCT}/${product.slug}`}
                internalLink
                dark
              >
                {DETAILS_LABEL}
              </ButtonLink>
            </div>
          </td>
        );
      })}
    </tr>
  );
};

export default memo(ProductSection);
