import { FC, memo, useState } from 'react';
import { useRouter } from 'next/router';
import { IconShoppingCart } from '@nzxt/react-icons';
import useUiStore, { getOpenCart } from '@stores/use-ui-store';
import { useTranslation as t } from '@utils/hooks';
import GTM from '@utils/gtm';
import useAddItem from '@framework/cart/use-add-item';
import type { Variant } from '@framework/api/types';
import slugify from '@utils/slugify';
import * as styles from './styles';

type Props = {
  selectedVariant: Variant;
  currencyCode: string;
  name: string;
};

const QuickBuy: FC<Props> = ({ selectedVariant, currencyCode, name }) => {
  const openCart = useUiStore(getOpenCart);
  const { asPath } = useRouter();

  const [loading, setLoading] = useState(false);
  const quantity = 1;

  const addItem = useAddItem();
  const addToCart = async (): Promise<void> => {
    setLoading(true);

    try {
      if (selectedVariant?.shopify_variants_id) {
        // eslint-disable-next-line security/detect-non-literal-fs-filename
        window?.Extend?.modal?.open({
          referenceId: selectedVariant?.shopify_variants_id,
          onClose: async (plan, product) => {
            if (plan && product && window?.ExtendShopify) {
              window.ExtendShopify?.getPlanVariant(
                {
                  referenceId: selectedVariant?.shopify_variants_id,
                  termLength: plan?.term,
                },
                async (err, planVariant) => {
                  if (err) {
                    return err;
                  }
                  const items = [
                    {
                      shopify_variants_id: selectedVariant?.shopify_variants_id,
                      quantity,
                    },
                    {
                      shopify_variants_id: planVariant.variantId,
                      quantity,
                      attributes: [
                        {
                          key: `Extend.IsExtendWarranty`,
                          value: `true`,
                        },
                      ],
                    },
                  ].filter(Boolean);

                  await addItem(items).then(() => {
                    openCart();
                    setLoading(false);
                  });

                  return planVariant;
                }
              );
            } else {
              await addItem({
                shopify_variants_id: selectedVariant?.shopify_variants_id,
                quantity,
              }).then(() => {
                openCart();
                setLoading(false);
              });
            }
          },
        });
      }
      GTM.dataLayer({
        dataLayer: {
          event: 'warrantyModal',
          triggerSource: 'Quick cart action',
        },
      });
    } catch (err) {
      setLoading(false);
    }
    // track add to cart for GA enhanced ecommerce
    GTM.dataLayer({
      dataLayer: {
        event: 'addToCartQuickBuy',
        ecommerce: {
          currencyCode,
          add: {
            products: [
              {
                name,
                id: selectedVariant?.id,
                price: selectedVariant?.price.toString(),
                brand: 'NZXT',
                variant: Array.isArray(selectedVariant?.color)
                  ? selectedVariant?.color.map(color => color.name).join('/')
                  : null,
                quantity,
                productTitle: name,
                pageName: asPath,
              },
            ],
          },
        },
      },
    });

    // track add to cart via facebook pixel
    if (window.fbq) {
      window.fbq('track', 'AddToCartQuickBuy', {
        content_name: name,
        content_ids: [selectedVariant?.id],
        content_type: 'product',
        value: selectedVariant?.price,
        currency: currencyCode,
      });
    }
  };

  return (
    <button
      onClick={addToCart}
      type="button"
      disabled={loading}
      className={styles.quickBuyButton}
      aria-label={`${t('add_to_cart')} ${name} ${selectedVariant?.color
        .map(color => color.name)
        .join('-')} `}
      id={`${slugify(name)}-quick-buy-button`}
    >
      <IconShoppingCart className={styles.shoppingCart} />
    </button>
  );
};

export default memo(QuickBuy);
