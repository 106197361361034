import { FC, memo } from 'react';
import Head from 'next/head';
import { NextSeo } from 'next-seo';
import {
  renderMetaTags,
  SeoOrFaviconTag,
  TitleMetaLinkTag,
} from 'react-datocms';
import type { NextSeoProps } from 'next-seo';
import type { SeoMetaTag } from '@framework/api/types';
import { SUPPORTED_LOCALES } from '@constants';

type Props = NextSeoProps & {
  cmsData?: SeoMetaTag[];
  canonical: string;
  baseUrl: string;
};

type LangAlternate = {
  hrefLang: string;
  href: string;
};

const buildLanguageAlternates = (
  baseUrl: string,
  canonical: string
): LangAlternate[] => {
  const alts = SUPPORTED_LOCALES.map(locale => ({
    hrefLang: locale,
    href: canonical.replace(baseUrl, `${baseUrl}/${locale}`),
  }));

  alts.push({ hrefLang: 'x-default', href: canonical });

  return alts;
};

const proxyOgImages = (
  cmsData: SeoMetaTag[] | SeoOrFaviconTag[]
): TitleMetaLinkTag[] => {
  // eslint-disable-next-line security/detect-non-literal-regexp
  const regex = new RegExp(`${process.env.NEXT_PUBLIC_CMS_ASSETS_URL}/`, 'g');

  const headData = cmsData.map(tag => {
    if (
      (tag.attributes &&
        tag.attributes.name &&
        tag.attributes.name === 'twitter:image') ||
      (tag.attributes &&
        tag.attributes.property &&
        tag.attributes.property === 'og:image')
    ) {
      // eslint-disable-next-line no-param-reassign
      tag.attributes.content = tag.attributes.content.replace(
        regex,
        `${process.env.NEXT_PUBLIC_BASE_URL}/assets/cms/`
      );
    }

    return tag;
  });

  return headData;
};

const HeadComponent: FC<Props> = ({ cmsData, baseUrl, canonical, ...rest }) => (
  <>
    <NextSeo
      canonical={canonical}
      languageAlternates={buildLanguageAlternates(baseUrl, canonical)}
      {...rest}
    />
    {cmsData && <Head>{renderMetaTags(proxyOgImages(cmsData))}</Head>}
  </>
);

export default memo(HeadComponent);
