import { classnames } from '@tailwindcss-classnames';

export const wrapper = classnames(
  'grid',
  'gap-12',
  'lg:grid-cols-12',
  'grid-cols-1'
);

export const listWrapper = classnames(
  'grid',
  'gap-12',
  'grid-cols-1',
  'md:grid-cols-2',
  'col-span-full',
  'lg:col-span-8',
  'justify-items-center'
);

export const selfCenter = 'self-center';

export const textColumn = classnames('lg:col-span-4', 'col-span-full');

export const composedTextColumn = (isOneRow: boolean): string =>
  classnames(textColumn, {
    [selfCenter]: isOneRow,
  });

export const textWrapper = classnames(
  'max-w-xs',
  'flex',
  'flex-col',
  'items-center',
  'md:py-12',
  'py-4',
  'h-full',
  'text-center',
  'mx-auto'
);

const sticky = classnames('md:sticky', 'md:top-1/2');

export const stickyText = (isOneRow: boolean): string =>
  classnames({
    [sticky]: !isOneRow,
  });

export const heading = classnames(
  'leading-snug',
  'uppercase',
  'text-3xl',
  'xl:text-4xl',
  '2xl:text-5xl',
  'mb-4',
  'md:mb-5',
  'h2'
);
export const subheading = classnames(
  'leading-relaxed',
  'text-nzxt-light-grey-800'
);
