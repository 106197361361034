/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, FC } from 'react';
import type { DatoProductImage, Variant } from '@framework/api/types';

import AvailabilityMessage from '@components/PDP/AvailabilityMessage';
import Image from '@components/Image';
import * as styles from './styles';

type Props = {
  name: string;
  slug: string;
  variants?: Variant[];
  collectionImage?: DatoProductImage;
  selectedVariant?: Variant;
  isRegionallyAvailable?: boolean;
  isAvailable?: boolean;
  isCustomCard?: boolean;
  isExpired?: boolean;
};

const CardImage: FC<Props> = ({
  slug,
  variants,
  collectionImage,
  selectedVariant,
  isRegionallyAvailable,
  isExpired = false,
}) => {
  const image =
    selectedVariant?.images?.length > 0
      ? selectedVariant?.images[0]?.responsiveImage
      : null;

  const isTopLeft = process.env.NEXT_PUBLIC_FEATURE_ENABLE_LEFT_CHIP === 'true';

  return (
    <div className={styles.imageWrapper}>
      {selectedVariant?.collectionPageMainImage ? (
        <Image
          className={styles.image}
          data-test-id={`${slug}-image-link`}
          data={selectedVariant?.collectionPageMainImage?.responsiveImage}
          width={
            selectedVariant?.collectionPageMainImage?.responsiveImage?.width
          }
          height={
            selectedVariant?.collectionPageMainImage?.responsiveImage?.height
          }
        />
      ) : (
        <>
          {image ? (
            <Image
              className={styles.image}
              data-test-id={`${slug}-image-link`}
              data={image}
              width={image?.width}
              height={image?.height}
            />
          ) : (
            <Image
              className={styles.image}
              data-test-id={`${slug}-image-link`}
              data={collectionImage?.responsiveImage}
              width={collectionImage?.responsiveImage?.width}
              height={collectionImage?.responsiveImage?.height}
            />
          )}
        </>
      )}
      {Array.isArray(variants) && variants.length > 0 && selectedVariant && (
        <div className={styles.textWrapper(isTopLeft)}>
          {variants && (
            <AvailabilityMessage
              isRegionallyAvailable={isRegionallyAvailable}
              variant={selectedVariant}
              isExpired={isExpired}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default memo(CardImage);
