import { FC, memo, useEffect } from 'react';
import type { DatoProduct } from '@framework/api/types';
import GTM from '@utils/gtm';
import ContentWrapper from '@components/ContentWrapper';
import ProductCard from '@components/ProductCard';
import * as styles from './styles';

type Props = {
  heading: string;
  shortDescription?: string;
  currencyCode: string;
  collectionName: string;
  products?: DatoProduct[];
};

const CollectionList: FC<Props> = ({
  heading,
  products,
  shortDescription,
  currencyCode,
  collectionName,
}) => {
  // track product impressions for GA enhanced ecommerce
  useEffect(() => {
    if (Array.isArray(products) && products.length > 0) {
      const impressions = [];

      products.forEach((product, i) => {
        impressions.push({
          name: product.name,
          id:
            Array.isArray(product.variants) && product.variants.length > 0
              ? product.variants[0].id
              : product.slug,
          price: product.collectionPagePrice
            ? product.collectionPagePrice.toString()
            : null,
          brand: 'NZXT',
          variant:
            Array.isArray(product.variants) &&
            product.variants.length > 0 &&
            Array.isArray(product.variants[0].color)
              ? product.variants[0].color.map(color => color.name).join('/')
              : null,
          list: `${collectionName} Collection Page`,
          position: i,
        });
      });

      GTM.dataLayer({
        dataLayer: {
          ecommerce: {
            currencyCode,
            impressions,
          },
        },
      });
    }
  }, [products, currencyCode, collectionName]);

  const isOneRow = products?.length <= 2;

  const hasProducts = Array.isArray(products) && products?.length > 0;

  return hasProducts ? (
    <ContentWrapper>
      <div className={styles.wrapper} data-test-id="collections-list-block">
        <div className={styles.composedTextColumn(isOneRow)}>
          {(heading || shortDescription) && (
            <div className={styles.textWrapper}>
              <div className={styles.stickyText(isOneRow)}>
                {heading && (
                  <h2
                    className={styles.heading}
                    data-test-id="collections-list-heading"
                  >
                    {heading}
                  </h2>
                )}
                {shortDescription && (
                  <p
                    className={styles.subheading}
                    data-test-id="collections-list-shrt-des"
                  >
                    {shortDescription}
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
        <div className={styles.listWrapper}>
          {products.map(product => (
            <div key={product.id}>
              <ProductCard
                collectionImage={product.collectionPageMainImage}
                name={product.name}
                slug={product.slug}
                variants={product.variants}
                productPrice={product.collectionPagePrice}
                currencyCode={currencyCode}
                shortDescription={product.shortDescription}
                availableRegions={product.availableRegions}
              />
            </div>
          ))}
        </div>
      </div>
    </ContentWrapper>
  ) : null;
};

export default memo(CollectionList);
