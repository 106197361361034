/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, FC } from 'react';
import StockNotification from '../StockNotification';
import * as styles from './styles';

type Props = {
  name: string;
  slug: string;
  shortDescription?: string;
  isExtended?: boolean;
  isSmall?: boolean;
  handleClick?: () => void;
  inventoryData?: {
    showInventory: boolean;
    stock?: number;
  };
};

const CardHeading: FC<Props> = ({
  name,
  slug,
  shortDescription,
  isExtended,
  isSmall = false,
  inventoryData,
}) => (
  <div
    {...(isExtended ? { className: styles.container } : {})}
    style={{ flex: '0.5 0 0%', flexBasis: 'max-content' }}
  >
    <div>
      {name && (
        <p className={styles.heading(isSmall)} data-test-id={`${slug}-heading`}>
          {name}
        </p>
      )}
    </div>
    <div className={styles.shortDescription}>
      <p className={styles.descriptionColor}>{shortDescription}</p>
    </div>
    <StockNotification spaceTop inventoryData={inventoryData} />
  </div>
);

export default memo(CardHeading);
