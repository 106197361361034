import { FC, memo, useMemo } from 'react';
import cn from 'clsx';
import { useTranslation as t } from '@utils/hooks';
import type { Variant } from '@framework/api/types';
import * as styles from './styles';

type Props = {
  variant: Variant;
  isExpired?: boolean;
  isRegionallyAvailable: boolean;
};

const getData = ({
  isValid,
  isLowStock,
  isOutOfStock,
  isOnSale,
  isComingSoon,
  isNewItem,
  isRegionallyAvailable,
  showSaleTag,
  isPromo,
  showPercentOff,
  percentOff,
  isExpired,
}: {
  isValid: boolean;
  isLowStock: boolean;
  isOutOfStock: boolean;
  isOnSale: boolean;
  isComingSoon: boolean;
  isNewItem: boolean;
  isRegionallyAvailable: boolean;
  isPromo?: boolean;
  showSaleTag?: boolean;
  showPercentOff?: boolean;
  percentOff?: number;
  isExpired?: boolean;
}): { message: string; style: string } => {
  if (!isRegionallyAvailable) {
    return { message: 'message_unavailable', style: styles.outOfStock };
  }

  if (isComingSoon) {
    return { message: 'message_coming_soon', style: styles.comingSoon };
  }

  if (isOutOfStock || isExpired) {
    return { message: 'message_out_of_stock', style: styles.outOfStock };
  }

  if (showPercentOff && percentOff) {
    return { message: 'discount_label', style: styles.onSale };
  }

  if (isOnSale || showSaleTag) {
    return { message: 'message_on_sale', style: styles.onSale };
  }

  if (isPromo) {
    return { message: 'message_promotion', style: styles.isPromo };
  }

  if (isLowStock) {
    return { message: 'message_low_stock', style: styles.lowStock };
  }

  if (isNewItem) {
    return { message: 'message_new_item', style: styles.newItem };
  }

  if (!isValid) {
    return { message: 'message_invalid', style: styles.invalid };
  }

  return null;
};

const AvailabilityMessage: FC<Props> = ({
  variant,
  isExpired,
  isRegionallyAvailable,
}) => {
  const {
    isValid,
    isLowStock,
    isOutOfStock,
    isOnSale,
    isComingSoon,
    isNewItem,
    showSaleTag,
    isPromo,
    showPercentOff,
    percentOff,
  } = variant;

  const currentData = useMemo(
    () =>
      getData({
        isValid,
        isLowStock,
        isOutOfStock,
        isOnSale,
        isComingSoon,
        isNewItem,
        isRegionallyAvailable,
        showSaleTag,
        isPromo,
        showPercentOff,
        percentOff,
        isExpired,
      }),
    [
      isValid,
      isLowStock,
      isOutOfStock,
      isOnSale,
      isComingSoon,
      isNewItem,
      isRegionallyAvailable,
      showSaleTag,
      isPromo,
      showPercentOff,
      percentOff,
      isExpired,
    ]
  );

  const composedClassName = useMemo(
    () => cn(styles.base, currentData && currentData.style),
    [currentData]
  );

  const id = currentData?.message ?? 'ok';
  const MESSAGE = t(id);

  const showOffLabel =
    isRegionallyAvailable &&
    !isComingSoon &&
    !isOutOfStock &&
    !isExpired &&
    showPercentOff &&
    percentOff;

  return currentData ? (
    <p className={composedClassName}>
      {showOffLabel ? `${percentOff}% ${MESSAGE}` : MESSAGE}
    </p>
  ) : null;
};

export default memo(AvailabilityMessage);
