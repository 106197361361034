/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, FC } from 'react';
import { useTranslation as t } from '@utils/hooks';
import * as styles from './styles';

type Props = {
  countdownData?: {
    showCountdown: boolean;
    countdownEndDate?: {
      days: number | string;
      hours: number | string;
      minutes: number | string;
      seconds: number | string;
      initialized: boolean;
      isExpired?: boolean;
    };
  };
  customContainerClass?: string;
};

const CountdownTimer: FC<Props> = ({ countdownData, customContainerClass }) => {
  const DEAL_LABEL = t('label_deal_ends');

  const timeCheck = countdownData?.countdownEndDate?.days === '00';

  const stylesToShow = customContainerClass || styles.countdownContainer;

  return !countdownData?.countdownEndDate?.isExpired &&
    timeCheck &&
    countdownData?.showCountdown ? (
    <div className={stylesToShow}>
      <p className={styles.countdown}>
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="w-4 h-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </span>{' '}
        <span>{`${DEAL_LABEL} ${countdownData?.countdownEndDate?.hours}:${countdownData?.countdownEndDate?.minutes}:${countdownData?.countdownEndDate?.seconds}`}</span>
      </p>
    </div>
  ) : null;
};

export default memo(CountdownTimer);
