/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, FC } from 'react';
import * as styles from './styles';

type Props = {
  extendedHeading?: string;
  extendedDetails?: string[];
  isFauxPrice?: boolean;
  isSmall?: boolean;
};

const CardExtendedDetails: FC<Props> = ({
  extendedHeading,
  extendedDetails,
  isFauxPrice = false,
  isSmall = false,
}) => {
  const hasDetail =
    extendedDetails &&
    Array.isArray(extendedDetails) &&
    extendedDetails?.length > 0;

  const minHeightStyles = !isFauxPrice ? { minHeight: '81px' } : {};

  return (
    <div
      className={styles.container(isFauxPrice)}
      style={{ ...minHeightStyles, flexBasis: 'max-content' }}
    >
      {extendedHeading && (
        <p className={styles.heading(hasDetail, isSmall)}>{extendedHeading}</p>
      )}
      {hasDetail &&
        extendedDetails?.map(detail => (
          <p key={detail} className={styles.subheading(isFauxPrice)}>
            {detail}
          </p>
        ))}
    </div>
  );
};

export default memo(CardExtendedDetails);
