/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, FC, useMemo } from 'react';
import type { DatoProduct } from '@framework/api/types';
import ContentWrapper from '@components/ContentWrapper';
import { useMedia } from '@utils/hooks';

import DataSection from './DataSection';
import ProductSection from './ProductSection';
import ScrollingTable from './ScrollingTable';
import * as styles from './styles';

type Props = {
  products: DatoProduct[];
  specData: Array<{ label: string; value: string | string[] }>;
  currencyCode: string;
};

const LargeTable: FC<Props> = ({ products, currencyCode, specData }) => {
  const isSmall = useMedia('sm');
  const isMd = useMedia('md');
  const isLarge = !isSmall && !isMd;
  const isLessThanOrEqualToThree = useMemo(
    () => products.length <= 3,
    [products]
  );
  return isLessThanOrEqualToThree && isLarge ? (
    <ContentWrapper>
      <table className={styles.largeTable}>
        <tbody className={styles.tableBody}>
          <ProductSection products={products} currencyCode={currencyCode} />
          {specData.length > 0 && <DataSection specs={specData} />}
        </tbody>
      </table>
    </ContentWrapper>
  ) : (
    <ScrollingTable
      products={products}
      specData={specData}
      currencyCode={currencyCode}
    />
  );
};

export default memo(LargeTable);
