import { classnames } from '@tailwindcss-classnames';
// countdown
export const countdown = classnames(
  'text-nzxt-light-grey-600',
  'font-semibold',
  'text-xs',
  'flex',
  'space-x-1',
  'items-start',
  'md:items-center'
);

export const countdownContainer = classnames('flex', 'col-span-2', 'w-full');
