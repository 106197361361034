/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, FC, useMemo, useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useTranslation as t, useCountdown } from '@utils/hooks';
import GTM from '@utils/gtm';
import { getRegion } from '@framework/api/utils/maxify';
import {
  CardWrapper,
  CardColorVariants,
  CardPrice,
  CardExtendedDetails,
  CardContentWrapper,
  UpperCard,
} from '@components/CardComponents';
import { ROUTE_PRODUCT } from '@constants';
import ButtonLink from '@components/ButtonLink';
import CountdownTimer from '@components/CardComponents/CountdownTimer';
import type {
  DatoProductImage,
  DatoAvailableLocales,
  Variant,
  DatoProductSpecs,
  Services,
} from '@framework/api/types';

import * as styles from './styles';

type Props = {
  currencyCode: string;
  name: string;
  slug: string;
  productPrice?: number;
  variants: Variant[];
  shortDescription?: string;
  collectionImage?: DatoProductImage;
  isNude?: boolean;
  availableRegions: DatoAvailableLocales[];
  isExtendedCard?: boolean;
  techSpecs?: DatoProductSpecs[];
  noVariantToggle?: boolean;
  isSmall?: boolean;
  regionalServices?: Services;
  eventType?: string;
};

const ProductCard: FC<Props> = ({
  currencyCode,
  name,
  slug,
  variants,
  shortDescription,
  collectionImage,
  isNude,
  availableRegions,
  techSpecs,
  isExtendedCard = false,
  noVariantToggle = false,
  isSmall = false,
  regionalServices,
  eventType,
}) => {
  const { locale } = useRouter();
  const region = getRegion(locale);
  const isRegionallyAvailable = useMemo(
    () =>
      Array.isArray(availableRegions)
        ? availableRegions.some(r => r.region === region)
        : false,
    [region, availableRegions]
  );

  const [selectedVariant, setSelectedVariant] = useState(variants[0]);

  const variantsCopy = [...variants];

  useEffect(() => {
    if (variants[0].isOutOfStock || !variants[0]?.isValid) {
      const isInStock = variantsCopy.find(item => !item.isOutOfStock);
      const indexOfInStock = variants.indexOf(isInStock);
      const selectedIndex = indexOfInStock >= 0 ? indexOfInStock : 0;
      setSelectedVariant(variants[selectedIndex]);
    } else {
      setSelectedVariant(variants[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variants]);

  const techSpecsSlimmed =
    Array.isArray(techSpecs) &&
    techSpecs?.length > 0 &&
    Object.values(techSpecs[0]?.specValues).slice(0, 3);

  const techSpecsLabel =
    Array.isArray(techSpecs) &&
    techSpecs?.length > 0 &&
    techSpecs[0]?.specCategory;

  const SHOP_LABEL = t('label_shop');

  const ecommerce = selectedVariant
    ? {
        click: {
          products: [
            {
              name,
              id: selectedVariant.id,
              price: selectedVariant?.price?.toString() ?? null,
              brand: 'NZXT',
              variant:
                selectedVariant?.color?.map(color => color.name).join('/') ??
                null,
            },
          ],
        },
      }
    : null;

  const handleEvent = (): void => {
    if (eventType) {
      GTM.dataLayer({
        dataLayer: {
          event: eventType,
          interactionType: 'productCard',
          ecommerce,
        },
      });
    }
  };

  const showCountdown =
    selectedVariant?.showCountdownTimer &&
    isRegionallyAvailable &&
    !selectedVariant?.isComingSoon &&
    !selectedVariant?.isOutOfStock;

  const countdownData = useCountdown(selectedVariant?.countdownEndDate);

  return name && slug ? (
    <CardWrapper>
      <UpperCard
        currencyCode={currencyCode}
        name={name}
        slug={slug}
        variants={variants}
        shortDescription={shortDescription}
        collectionImage={collectionImage}
        isNude={isNude}
        availableRegions={availableRegions}
        isExtendedCard={isExtendedCard}
        isSmall={isSmall}
        regionalServices={regionalServices}
        eventType={eventType}
        selectedVariant={selectedVariant}
        isExpired={showCountdown ? countdownData?.isExpired : false}
      />

      <CardContentWrapper>
        {isExtendedCard && (
          <CardExtendedDetails
            extendedHeading={techSpecsLabel}
            extendedDetails={techSpecsSlimmed}
          />
        )}

        {!isExtendedCard && (
          <div className={styles.descGrid}>
            <CardPrice
              currencyCode={currencyCode}
              slug={slug}
              handleClick={handleEvent}
              selectedVariant={selectedVariant}
              isRegionallyAvailable={isRegionallyAvailable}
              isSmall={isSmall}
            />

            {!isNude && (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <CardColorVariants
                isRegionallyAvailable={isRegionallyAvailable}
                variants={variants}
                selectedVariant={selectedVariant}
                setSelectedVariant={setSelectedVariant}
                productName={name}
                slug={slug}
                handleClick={handleEvent}
                isFaux={noVariantToggle}
                isSmall={isSmall}
              />
            )}
            {selectedVariant?.countdownEndDate && (
              <CountdownTimer
                countdownData={{
                  showCountdown,
                  countdownEndDate: countdownData,
                }}
              />
            )}
          </div>
        )}
        {isExtendedCard && (
          <div className={styles.fullWidth}>
            <div className={styles.extendedDetailsWrapper(true)}>
              <CardPrice
                currencyCode={currencyCode}
                slug={slug}
                handleClick={handleEvent}
                selectedVariant={selectedVariant}
                isRegionallyAvailable={isRegionallyAvailable}
                isSmall={isSmall}
              />
              {selectedVariant?.countdownEndDate && (
                <CountdownTimer
                  countdownData={{
                    showCountdown,
                    countdownEndDate: countdownData,
                  }}
                />
              )}
            </div>

            <div className={styles.extendedDetailsWrapper(false)}>
              <ButtonLink
                asButton
                buttonStyle="primary"
                href={`/${ROUTE_PRODUCT}/${slug}`}
                className={styles.buttonLabel(isSmall)}
                customMargin
                internalLink
              >
                {SHOP_LABEL}
              </ButtonLink>
            </div>
          </div>
        )}
      </CardContentWrapper>
    </CardWrapper>
  ) : null;
};

export default memo(ProductCard);
