/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, FC } from 'react';
import type { DatoProduct } from '@framework/api/types';

import DataSection from './DataSection';
import ProductSection from './ProductSection';
import * as styles from './styles';

type Props = {
  products: DatoProduct[];
  specData: Array<{ label: string; value: string | string[] }>;
  currencyCode: string;
};

const ScrollingTable: FC<Props> = ({ products, currencyCode, specData }) => (
  <div className={styles.scrollContainer}>
    <table className={styles.scrollTableWrapper}>
      <tbody className={styles.tableBody}>
        <ProductSection
          isScroll
          products={products}
          currencyCode={currencyCode}
        />
        <DataSection isScroll specs={specData} />
      </tbody>
    </table>
  </div>
);

export default memo(ScrollingTable);
