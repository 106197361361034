/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useRouter } from 'next/router';
import * as React from 'react';
import { getFromLocalStorage } from '@utils/get-from-local-storage';
import useAuthStore from '@stores/use-auth-store';
import { useTranslation as t } from '@utils/hooks';
import { User } from '../../../types';
// import * as styles from './styles';

export interface WithAuthProps {
  user: User;
}

const HOME_ROUTE = '/';
const LOGIN_ROUTE = '/login';

enum RouteRole {
  /**
   * For authentication pages
   * @example /login /register
   */
  auth,
  /**
   * Optional authentication
   * It doesn't push to login page if user is not authenticated
   */
  optional,
  /**
   * For all authenticated user
   * will push to login if user is not authenticated
   */
  all,
}

/**
 * Add role-based access control to a component
 *
 * @see https://react-typescript-cheatsheet.netlify.app/docs/hoc/full_example/
 * @see https://github.com/mxthevs/nextjs-auth/blob/main/src/components/withAuth.tsx
 */
export default function withAuth<
  T extends { [key: string]: unknown } = { [key: string]: unknown }
>(Component: React.ComponentType<T>, routeRole: keyof typeof RouteRole) {
  const ComponentWithAuth = (props: Omit<T, keyof WithAuthProps>) => {
    const router = useRouter();
    const { query } = router;

    const isAuthenticated = useAuthStore.useIsAuthenticated();
    const isLoading = useAuthStore.useIsLoading();
    const login = useAuthStore.useLogin();
    const logout = useAuthStore.useLogout();
    const stopLoading = useAuthStore.useStopLoading();
    const user = useAuthStore.useUser();

    const checkAuth = React.useCallback(() => {
      const token = getFromLocalStorage('token');
      if (!token && isAuthenticated) {
        logout();
        stopLoading();
        return;
      }
      const loadUser = async (): Promise<void> => {
        try {
          const res = await fetch('/me');
          const data: User = await res.json();

          login({
            ...data,
            token,
          });
        } catch (err) {
          localStorage.removeItem('token');
        } finally {
          stopLoading();
        }
      };

      if (!isAuthenticated) {
        loadUser();
      }
    }, [isAuthenticated, login, logout, stopLoading]);

    React.useEffect(() => {
      // run checkAuth every page visit
      checkAuth();

      // run checkAuth every focus changes
      window.addEventListener('focus', checkAuth);
      return () => {
        window.removeEventListener('focus', checkAuth);
      };
    }, [checkAuth]);

    React.useEffect(() => {
      if (!isLoading) {
        if (isAuthenticated) {
          // Prevent authenticated user from accessing auth or other role pages
          if (routeRole === 'auth') {
            if (query?.redirect) {
              router.replace(query.redirect as string);
            } else {
              router.replace(HOME_ROUTE);
            }
          }
        } else if (routeRole !== 'auth' && routeRole !== 'optional') {
          // Prevent unauthenticated user from accessing protected pages
          router.replace(
            `${LOGIN_ROUTE}?redirect=${router.asPath}`,
            `${LOGIN_ROUTE}`
          );
        }
      }
    }, [isAuthenticated, isLoading, query, router, user]);

    if (
      // If unauthenticated user want to access protected pages
      (isLoading || !isAuthenticated) &&
      // auth pages and optional pages are allowed to access without login
      routeRole !== 'auth' &&
      routeRole !== 'optional'
    ) {
      return <div>{`${t('loading')}...`}</div>;
    }

    return <Component {...(props as T)} user={user} />;
  };

  return ComponentWithAuth;
}
