import { classnames } from '@tailwindcss-classnames';
// stock
export const inventoryCount = classnames(
  'text-nzxt-green-400',
  'font-semibold',
  'text-xs'
);

const topMargin = classnames('mt-3');

export const countContainer = (spaceTop: boolean): string =>
  classnames('flex', 'col-span-2', 'w-full', 'mb-1', { [topMargin]: spaceTop });
