import * as Sentry from '@sentry/nextjs';
import { useRouter } from 'next/router';
import ErrorPage from 'next/error';
import { GetStaticProps, InferGetStaticPropsType, GetStaticPaths } from 'next';
import dynamic from 'next/dynamic';
import { IconLoading } from '@nzxt/react-icons';
import Head from '@components/Head';
import Layout from '@components/Layout';
import ContentWrapper from '@components/ContentWrapper';
import { SectionWrapper } from '@components/CollectionPage';
import getUrlByEnv from '@utils/get-url-by-env';
import getNav from '@framework/api/operations/get-nav';
import { STAGING, ROUTE_OAUTH, REVALIDATE_VALUES } from '@constants';
import { TERM_PAGE_SLUGS, TERM_TYPES } from '@framework/api/types';
import getLegal from '@framework/api/operations/get-legal';
import decoratePageContent from '@utils/decorate-page-content';
import withAuth from '@components/Auth/hoc/withAuth';

const PAGES = [
  'login',
  'logout',
  'consent',
  'signup',
  'reset-verification',
  'forgot-password',
  'email-verification',
  'error',
];

const Loading = (): JSX.Element => <IconLoading className="loading-icon" />;

const LoginView = dynamic(() => import('@components/Auth/LoginView'), {
  loading: () => <Loading />,
});

const LogoutView = dynamic(() => import('@components/Auth/Signout'), {
  loading: () => <Loading />,
});

const ConsentView = dynamic(() => import('@components/Auth/ConsentView'), {
  loading: () => <Loading />,
});

const SignUpView = dynamic(() => import('@components/Auth/SignUpView'), {
  loading: () => <Loading />,
});

const ResetView = dynamic(() => import('@components/Auth/ResetView'), {
  loading: () => <Loading />,
});

const ForgotView = dynamic(() => import('@components/Auth/ForgotView'), {
  loading: () => <Loading />,
});

const VerifyView = dynamic(() => import('@components/Auth/VerifyView'), {
  loading: () => <Loading />,
});

const ErrorView = dynamic(() => import('@components/Auth/ErrorView'), {
  loading: () => <Loading />,
});

export const getStaticProps: GetStaticProps = async context => {
  const { locale, locales, params, preview } = context;
  const envName =
    process.env.VERCEL_GITHUB_COMMIT_REF === STAGING
      ? process.env.NEXT_PUBLIC_STAGING_ENV_NAME
      : process.env.NEXT_PUBLIC_ENV_NAME;

  const baseUrl = getUrlByEnv(envName);

  const slug = Array.isArray(params.slug) ? params.slug[0] : params.slug;
  const page = PAGES.find(p => p === slug);
  const nav = await getNav({ slug: 'main', locale, preview });

  const termPageSlugs = [
    TERM_PAGE_SLUGS[TERM_TYPES.TOS],
    TERM_PAGE_SLUGS[TERM_TYPES.PrivacyPolicy],
    TERM_PAGE_SLUGS[TERM_TYPES.PartnerProgram],
  ];

  const termPages = await getLegal({
    slug: termPageSlugs,
    locale,
    preview,
  });

  const newTermPages = await Promise.all(
    termPages.map(async ({ content, ...rest }) => {
      const decoratedContent = await decoratePageContent(content);
      return { content: decoratedContent, ...rest };
    })
  );

  if (!page) {
    if (!slug.endsWith('.css.map')) {
      Sentry.captureException(
        new Error(`User page with slug '${slug}' not found`)
      );
    }

    return {
      notFound: true,
    };
  }

  return {
    props: {
      preview: preview || false,
      envName,
      baseUrl,
      locale,
      locales: JSON.stringify(locales),
      page,
      nav,
      termPages: newTermPages,
    },
    revalidate: REVALIDATE_VALUES.standard,
  };
};

export const getStaticPaths: GetStaticPaths = async () => {
  const allOAuthPages = PAGES;

  return {
    paths: allOAuthPages?.map(page => `/${ROUTE_OAUTH}/${page}`) || [],
    fallback: 'blocking',
  };
};

type Props = {
  baseUrl: string;
  page: string;
};

const OAuth: React.FC<Props> = ({
  baseUrl,
  page,
  // termPages,
}) => {
  const router = useRouter();
  const { query } = router;

  return !page ? (
    <ErrorPage statusCode={404} />
  ) : (
    <>
      <Head
        canonical={`${baseUrl}/${ROUTE_OAUTH}/${query.slug}`}
        baseUrl={baseUrl}
      />
      <SectionWrapper>
        <ContentWrapper>
          {page === 'login' && <LoginView pageType="standalone" />}
          {page === 'logout' && <LogoutView pageType="standalone" />}
          {page === 'consent' && <ConsentView pageType="standalone" />}
          {page === 'signup' && <SignUpView pageType="standalone" />}
          {page === 'reset-verification' && <ResetView pageType="standalone" />}
          {page === 'forgot-password' && <ForgotView pageType="standalone" />}
          {page === 'email-verification' && (
            <VerifyView pageType="standalone" />
          )}
          {page === 'error' && <ErrorView />}
        </ContentWrapper>
      </SectionWrapper>
    </>
  );
};

const OAuthPageWithAuth: InferGetStaticPropsType<typeof getStaticProps> =
  withAuth(OAuth, 'auth');

OAuthPageWithAuth.Layout = Layout;

export default OAuthPageWithAuth;
