import { classnames } from '@tailwindcss-classnames';

export const image = classnames('z-0', 'max-w-lg');
export const imageWrapper = classnames(
  'relative',
  'w-full',
  'p-4',
  'xl:p-10',
  'bg-nzxt-light-grey-50'
);

const bottomRight = classnames('bottom-0', 'left-0');
const topLeft = classnames('top-10', 'left-10', 'h-full');

export const textWrapper = (isTopLeft: boolean): string =>
  classnames('absolute', {
    [topLeft]: isTopLeft,
    [bottomRight]: !isTopLeft,
  });

export const quickBuyButton = classnames(
  'bg-nzxt-volt-400',
  'border',
  'rounded-full',
  'p-2.5',
  'scale-100',
  'transform',
  'duration-200',
  'hover:scale-110'
);
export const shoppingCart = classnames(
  'text-white',
  'fill-current',
  'h-5',
  'w-5'
);
