import { classnames } from '@tailwindcss-classnames';

const priceDiscounted = classnames(
  'line-through',
  'mr-2',
  'text-nzxt-light-grey-600'
);
const smallText = classnames('text-xs', 'md:text-sm');

export const priceContainer = classnames('flex', 'flex-wrap', 'w-full');

export const discountedPricedText = (isSmall: boolean): string =>
  classnames('text-nzxt-light-grey-800', { [smallText]: isSmall });
export const isOnSale = (salePrice: boolean, isSmall: boolean): string =>
  classnames({ [priceDiscounted]: salePrice, [smallText]: isSmall });
const noOpacity = classnames('opacity-0');
const fullOpacity = classnames('opacity-100');

const smallSpan = classnames('col-span-2', 'md:col-span-1');
export const isVisible = (hasVisibility: boolean, isSmall: boolean): string =>
  classnames({
    [smallSpan]: isSmall,
    [noOpacity]: !hasVisibility,
    [fullOpacity]: hasVisibility,
  });
