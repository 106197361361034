/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, useMemo, FC } from 'react';
import type { DatoProduct } from '@framework/api/types';

import SingleItemTable from './SingleItemTable';
import LargeTable from './LargeTable';

type Props = {
  products: DatoProduct[];
  currencyCode: string;
};

const Table: FC<Props> = ({ products, currencyCode }) => {
  const decoratedProducts = useMemo(
    () =>
      products.map(product => {
        // eslint-disable-next-line no-param-reassign
        product.decoratedSpecs = product.specs
          ? Object.keys(product.specs).map(spec => ({
              label: spec,
              value: product.specs[spec],
            }))
          : [];
        return product;
      }),
    [products]
  );

  const specs = useMemo(
    () =>
      decoratedProducts.map(product =>
        product.decoratedSpecs.map(item => item)
      ),
    [decoratedProducts]
  );

  const newSpecs = useMemo(
    () =>
      specs.reduce((result, current) => {
        result.push(...current);
        return result;
      }, []),
    [specs]
  );

  const output = useMemo(
    () =>
      newSpecs.reduce((o, cur) => {
        const occurs = o.reduce(
          (n, item, i) => (item.label === cur.label ? i : n),
          -1
        );

        if (occurs >= 0) {
          // eslint-disable-next-line no-param-reassign
          o[occurs].value = o[occurs].value.concat(cur.value);
        } else {
          const obj = {
            label: cur.label,
            value: [cur.value],
          };
          // eslint-disable-next-line no-param-reassign
          o = o.concat([obj]);
        }

        return o;
      }, []),
    [newSpecs]
  );

  return (
    <>
      {products && output && (
        <>
          {products.length > 1 ? (
            <LargeTable
              products={products}
              specData={output}
              currencyCode={currencyCode}
            />
          ) : (
            <SingleItemTable products={decoratedProducts} />
          )}
        </>
      )}
    </>
  );
};

export default memo(Table);
