import { classnames } from '@tailwindcss-classnames';

const isDefault = classnames('md:mx-2');
const disabledButton = classnames(
  'text-nzxt-light-grey-500',
  'bg-nzxt-light-grey-300',
  'cursor-not-allowed',
  'hover:text-nzxt-light-grey-500',
  'hover:bg-nzxt-light-grey-300'
);
const isActive = classnames('opacity-100', 'pointer-events-auto', 'group');

export const button = (customMargin: boolean, isDisabled: boolean): string =>
  classnames(
    {
      [isDefault]: !customMargin,
    },
    {
      [disabledButton]: isDisabled,
      [isActive]: !isDisabled,
    },
    'min-w-max',
    'flex',
    'items-center',
    'font-medium',
    'cursor-pointer',
    'text-center',
    'justify-around',
    'transition',
    'whitespace-nowrap',
    'h-11'
  );

const disabledLink = classnames(
  '!text-nzxt-light-grey-500',
  'cursor-not-allowed'
);

export const linkBase = (isDisabled: boolean): string =>
  classnames(
    {
      [disabledLink]: isDisabled,
      [isActive]: !isDisabled,
    },
    'cursor-pointer',
    'underline-offset'
  );

export const buttonModeLight = {
  primary: classnames(
    'bg-nzxt-volt-400',
    'hover:bg-nzxt-volt-500',
    'px-4',
    '2xl:px-10',
    'min-w-120',
    'md:min-w-140',
    'rounded-md',
    'text-white',
    'active:bg-nzxt-volt-500'
  ),
  secondary: classnames(
    'bg-nzxt-dark-grey-400',
    'hover:bg-nzxt-dark-grey-500',
    'active:bg-nzxt-dark-grey-700',
    'text-white',
    'px-4',
    '2xl:px-10',
    'min-w-120',
    'md:min-w-140',
    'rounded-md'
  ),
  danger: classnames(
    'bg-nzxt-red-200',
    'text-white',
    'hover:bg-nzxt-red-100',
    'px-4',
    '2xl:px-10',
    'min-w-120',
    'md:min-w-140',
    'rounded-md'
  ),
};

export const buttonModeDark = {
  primary: classnames(
    'border-white',
    'border-2',
    'text-white',
    'px-4',
    '2xl:px-10',
    'min-w-120',
    'md:min-w-140',
    'rounded-md'
  ),
  secondary: classnames(
    'bg-nzxt-dark-grey-400',
    'hover:bg-nzxt-dark-grey-500',
    'text-white',
    'px-4',
    '2xl:px-10',
    'min-w-120',
    'md:min-w-140',
    'rounded-md',
    'active:bg-nzxt-dark-grey-700'
  ),
  danger: classnames(
    'bg-nzxt-red-100',
    'text-white',
    'hover:bg-nzxt-red-200',
    'px-4',
    '2xl:px-10',
    'min-w-120',
    'md:min-w-140',
    'rounded-md'
  ),
};

export const baseLinkLight = {
  primary: classnames('text-nzxt-volt-400', 'hover:underline'),
  secondary: classnames('text-nzxt-dark-grey-400', 'hover:underline'),
  tertiary: classnames('text-nzxt-blue-400', 'hover:underline'),
  danger: classnames('text-nzxt-red-200', 'hover:underline'),
};

export const baseLinkDark = {
  primary: classnames('text-white', 'hover:underline'),
  secondary: classnames('text-nzxt-dark-grey-400', 'hover:underline'),
  tertiary: classnames('text-nzxt-blue-300', 'hover:underline'),
  danger: classnames('text-nzxt-red-200', 'hover:underline'),
};
