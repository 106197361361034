import { classnames } from '@tailwindcss-classnames';

export const descGrid = classnames(
  'grid',
  'gap-0',
  'grid-cols-2',
  'items-end',
  'pt-2',
  'justify-end'
);

export const servicesWrapper = classnames('flex', 'flex-col', 'justify-end');
export const smallText = classnames(
  'text-xs',
  'text-nzxt-light-grey-400',
  'mb-1',
  'md:hidden',
  'visible',
  'flex-grow'
);

export const wrapper = classnames('flex', 'flex-col', 'h-full');
export const growWrapper = classnames('flex-grow', 'mb-3', 'mt-1');
const defaultPlacement = classnames('top-10', 'right-10');
const smallPlacement = classnames(
  'md:top-10',
  'md:right-10',
  'hidden',
  'md:block'
);

export const quickBuyPlacement = (isSmall: boolean): string =>
  classnames('absolute', 'pl-4', 'pb-4', 'md:pl-2', 'md:pb-4', {
    [defaultPlacement]: !isSmall,
    [smallPlacement]: isSmall,
  });
