/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, FC, useMemo } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation as t } from '@utils/hooks';
import GTM from '@utils/gtm';
import { getRegion } from '@framework/api/utils/maxify';
import { ROUTE_PRODUCT } from '@constants';
import type {
  DatoProductImage,
  DatoAvailableLocales,
  Variant,
  DatoProductSpecs,
  Services,
} from '@framework/api/types';
import QuickBuy from '@components/CardComponents/CardImage/QuickBuy';
import CardHeading from '../CardHeading';
import CardService from '../CardService';
import CardImage from '../CardImage';

import * as styles from './styles';

type Props = {
  currencyCode?: string;
  name?: string;
  slug: string;
  productPrice?: number;
  variants?: Variant[];
  selectedVariant?: Variant;
  shortDescription?: string;
  collectionImage?: DatoProductImage;
  isNude?: boolean;
  availableRegions?: DatoAvailableLocales[];
  isExtendedCard?: boolean;
  techSpecs?: DatoProductSpecs[];
  noVariantToggle?: boolean;
  isSmall?: boolean;
  regionalServices?: Services;
  eventType?: string;
  isCustomCard?: boolean;
  isExpired?: boolean;
};

const UpperCard: FC<Props> = ({
  currencyCode,
  name,
  slug,
  variants,
  shortDescription,
  collectionImage,
  isNude,
  availableRegions,
  isExtendedCard = false,
  isSmall = false,
  isCustomCard = false,
  regionalServices,
  eventType,
  selectedVariant,
  isExpired = false,
}) => {
  const { locale } = useRouter();
  const region = getRegion(locale);

  const isRegionallyAvailable = useMemo(
    () =>
      Array.isArray(availableRegions)
        ? availableRegions.some(r => r.region === region)
        : false,
    [region, availableRegions]
  );

  const isAvailable = !isCustomCard
    ? !selectedVariant.isComingSoon &&
      !isNude &&
      selectedVariant?.isValid &&
      isRegionallyAvailable &&
      !selectedVariant.isOutOfStock
    : true;

  const variantColorsTotal =
    Array.isArray(variants) && variants?.map(({ color }) => color);
  const COLORS = t('message_colors');
  const COLOR = t('message_color');

  const colorText =
    variantColorsTotal?.length > 1
      ? `${variantColorsTotal?.length} ${COLORS}`
      : `${variantColorsTotal?.length} ${COLOR}`;

  const ecommerce = selectedVariant
    ? {
        click: {
          products: [
            {
              name,
              id: selectedVariant.id,
              price: selectedVariant?.price?.toString() ?? null,
              brand: 'NZXT',
              variant:
                selectedVariant?.color?.map(color => color.name).join('/') ??
                null,
            },
          ],
        },
      }
    : null;

  const handleEvent = (): void => {
    if (eventType) {
      GTM.dataLayer({
        dataLayer: {
          event: eventType,
          interactionType: 'productCard',
          ecommerce,
        },
      });
    }
  };

  const event = selectedVariant ? 'productClick' : 'clickToBuild';
  const handleLinkClick = (): void => {
    GTM.dataLayer({
      dataLayer: {
        event: typeof eventType !== undefined ? eventType : event,
        ecommerce,
      },
    });
  };

  const linkage = isCustomCard ? slug : `/${ROUTE_PRODUCT}/${slug}`;

  const hasPromo = Boolean(
    regionalServices?.shippingNotices ||
      regionalServices?.bundlesPromos?.length > 0
  );

  const showInventory =
    selectedVariant?.showInventoryCount &&
    isRegionallyAvailable &&
    !selectedVariant?.isComingSoon &&
    !selectedVariant?.isOutOfStock;

  return name && slug ? (
    <>
      {isAvailable && !isExpired && (
        <div
          className={styles.quickBuyPlacement(isSmall)}
          style={{ zIndex: 1 }}
        >
          <QuickBuy
            selectedVariant={selectedVariant}
            currencyCode={currencyCode}
            name={name}
          />
        </div>
      )}
      <Link href={linkage} passHref>
        <a
          onClick={() => handleLinkClick()}
          onKeyDown={() => handleLinkClick()}
          role="link"
          tabIndex={0}
          className={styles.wrapper}
        >
          <CardImage
            name={name}
            slug={slug}
            variants={variants}
            collectionImage={collectionImage}
            selectedVariant={selectedVariant}
            isRegionallyAvailable={isRegionallyAvailable}
            isAvailable={isAvailable}
            isExpired={isExpired}
          />
          <div className={styles.growWrapper}>
            <CardHeading
              name={name}
              slug={slug}
              shortDescription={shortDescription}
              isExtended={isExtendedCard}
              isSmall={isSmall}
              handleClick={handleEvent}
              inventoryData={{
                showInventory,
                stock: selectedVariant?.stock,
              }}
            />
          </div>
          {!isExtendedCard && (
            <>
              {hasPromo ? (
                <div className={styles.servicesWrapper}>
                  {regionalServices?.shippingNotices ? (
                    <CardService
                      slug={slug}
                      handleClick={handleEvent}
                      service={regionalServices?.shippingNotices}
                    />
                  ) : null}
                  {regionalServices?.bundlesPromos ? (
                    <CardService
                      slug={slug}
                      handleClick={handleEvent}
                      service={regionalServices?.bundlesPromos}
                    />
                  ) : null}
                </div>
              ) : null}

              {isSmall && <p className={styles.smallText}>{colorText}</p>}
            </>
          )}
        </a>
      </Link>
    </>
  ) : null;
};

export default memo(UpperCard);
