/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, FC } from 'react';

import * as styles from './styles';

type Props = {
  decoratedSpecs: Array<{ label: string; value: string }>;
};

const SingleItemTableSpecs: FC<Props> = ({ decoratedSpecs }) => (
  <>
    {decoratedSpecs.map(spec => (
      <div key={spec.label} className={styles.specsWrapper}>
        <p className={styles.specCategory}>{spec.label}</p>

        <p className={styles.specValueDetail}>{spec.value}</p>
      </div>
    ))}
  </>
);

export default memo(SingleItemTableSpecs);
