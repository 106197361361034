import { memo, FC } from 'react';
import CellItem from './CellItem';

import * as styles from './styles';

type Props = {
  specs: Array<{ label: string; value: string | string[] }>;
  isScroll?: boolean;
};

const DataSection: FC<Props> = ({ specs, isScroll }) => (
  <>
    {specs.map(({ label, value }) => (
      <tr key={label}>
        <th className={styles.dataTableHeadings(isScroll)} scope="row">
          {label}
        </th>
        {value && <CellItem label={label} value={value} />}
      </tr>
    ))}
  </>
);

export default memo(DataSection);
