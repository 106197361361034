/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, FC } from 'react';
import { useRouter } from 'next/router';
import parseLocale from '@utils/parse-locale';
import getStockNotification from '@utils/get-stock-notification';
import { DEFAULT_LANGUAGE_CODE } from '@constants';

import * as styles from './styles';

type Props = {
  inventoryData?: {
    showInventory: boolean;
    stock?: number;
  };
  spaceTop?: boolean;
};

const StockNotification: FC<Props> = ({ inventoryData, spaceTop = false }) => {
  // set filter for language
  const router = useRouter();
  const { locale, defaultLocale } = router;
  const parsedLocale = parseLocale(defaultLocale, locale);
  const lang = parsedLocale[0] || DEFAULT_LANGUAGE_CODE;

  // Show if equal to or less than 15
  // Does not show if greater than 15
  // Does not show if stock is 0
  const showStockDecrement =
    inventoryData?.stock <= 15 && inventoryData?.stock > 0;

  return showStockDecrement && inventoryData?.showInventory ? (
    <div className={styles.countContainer(spaceTop)}>
      <p className={styles.inventoryCount}>
        {getStockNotification(lang, inventoryData?.stock || 0)}
      </p>
    </div>
  ) : null;
};

export default memo(StockNotification);
