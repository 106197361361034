import { classnames } from '@tailwindcss-classnames';

export const optionsGrid = classnames('space-y-2', 'flex');
export const copyGrid = classnames(
  optionsGrid,
  'items-end',
  'text-nzxt-light-grey-800'
);
export const colorSelectItem = classnames('flex', 'ml-auto', 'items-start');
export const lightGreyCopy = classnames(
  'ml-auto',
  'flex',
  'text-xs',
  'my-auto',
  'text-nzxt-light-grey-600',
  'underline',
  'mr-2'
);
