import { memo, FC } from 'react';
import type { DatoProduct } from '@framework/api/types';
import { Table } from './Table';

type Props = {
  heading?: string;
  products?: DatoProduct[];
  currencyCode: string;
};

const ComparisonTable: FC<Props> = ({ products, currencyCode }) => (
  <Table products={products} currencyCode={currencyCode} />
);

export default memo(ComparisonTable);
